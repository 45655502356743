export function getSequenceTypes(id_post, types, title, langs, parrent_id, index, isArray = false){
    
    const current_type = types.find(type => type.title === title)
    let isParent = false;
    types.forEach(el => {
        if(el.id_allowed?.includes(current_type.id)) isParent = true;
    })
    console.log("type, ",current_type);
    const sequence = {title: '', id_type: current_type.id, id_parent: isParent? parrent_id : 0, id_post: isParent? undefined : id_post, index: isParent? index + 1 : undefined, values: [], isArray}
    sequence.values = current_type.sequence.map((el, index) => {
        const el_type = types.find(type => type.id === el)
        
        if(el < 0 || el_type.sequence?.length){
            
            const block_type = types.find(type => type.id === Math.abs(el))
            const result = {title: block_type.title, id: block_type.id, values: []}
            result.values = getTypes(id_post, block_type, types, langs, index, isArray)
            result.isBlock = true
            result.isArray = el < 0? true : false
            result.id_type = Math.abs(el)
            return result
        }

        const return_type = types.find(type => type.id === el)
        let values
        const new_date = `${Math.floor(Math.random() * 999999999999)}`
        if(return_type.type === "text" || return_type.type === "link"){
            values = langs.map(ln => {
                return {lang_id: ln.id, value: ""}
            })
        }
        if(return_type.type === "image" || return_type.type === "video"){
            values = {pseudo: new_date}
        }
        
        const result = {...return_type, id_type: return_type.id, values: values, editId: new_date}
        return result
        
    })
    sequence.title = title
    
    return sequence

}

function getTypes(id_post, type, types, langs, index){
    const sequence = type.sequence
    return sequence.map((el, index) => {
        const el_type = types.find(type => type.id === el)
        if(el < 0 || el_type.sequence?.length){
            console.log(Math.abs(el));
            const block_type = types.find(type => type.id === Math.abs(el))
            const result = {title: block_type.title, id: block_type.id, values: []}
            result.values = getTypes(block_type.id, block_type, types, langs)
            result.isBlock = true
            result.isArray = el < 0? true : false
            result.id_type = Math.abs(el)
            return result

        }
        const return_type = types.find(type => type.id === el)
        let values
        const new_date = `${Math.floor(Math.random() * 999999999999)}`
        if(return_type.type === "text" || return_type.type === "link"){
            values = langs?.map(ln => {
                return {lang_id: ln.id, value: ""}
            })
        }
        if(return_type.type === "image" || return_type.type === "video"){
            values = {pseudo: new_date}
        }
        const result = {...return_type, id_type: return_type.id, values: values, editId: new_date}
        return result
    })
}

export async function Timeout(ms){
    return new Promise(resolve => setTimeout(resolve, ms))
}