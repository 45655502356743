import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
const api = process.env.REACT_APP_API_ADDRESS;


export const CheckToken = ({ children }) => {
    const currentUrl = window.location.href;
    const navigate = useNavigate();
    const token = localStorage.getItem('token');

    async function check(){
        try{
            const response = await fetch(`${api}/api/user/check`,{
            method: 'GET',
            headers: {
                'token': token? token : "",
            },
            })


            if(response.status === 401 && !currentUrl.includes('registration')) navigate("/auth/");
            if(response.status === 200){
                const data = await response.json();
                localStorage.setItem("token", await data.token);
                localStorage.setItem("userData", JSON.stringify(data.userData));
                if(currentUrl.includes('registration') || currentUrl.includes('auth')) navigate("/")
            }
        }catch(error){
            console.log(error);
        }
    }

    useEffect(()=>{
        const current_token = localStorage.getItem('token');
        if(!current_token && !currentUrl.includes('registration')) {
            navigate("/auth/");
        }
        check();
    },[]);

    
    return children;
};
