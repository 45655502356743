import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import { useGetBlocksTypesQuery } from '../../redux/apis/blocksApi';
import { useGetLangsQuery } from '../../redux/apis/langApi';
import { mediaApi, useGetMediaQuery } from '../../redux/apis/mediaApi';
import { useUpdatePostMediaMutation, useUpdatePostTermsMutation } from '../../redux/apis/postsApi';
import { termsApi, useGetTermsQuery, useUpdateTermsMutation } from '../../redux/apis/termsApi';
import { Toast } from '../../utils/hocs/toast';


export const EditModal = ({type, id, pseudo, close, page})=>{
    
    let groups_view;
    let groups_view_media;
    let current_group;
    let current_group_media;
    const dispatch = useDispatch();
    
    const img_input = useRef();
    const img_wrapper = useRef();
    const group_select = useRef();
    const type_select = useRef();
    const error_text = useRef();
    const video = useRef();
    const [formContent, setFormContent] = useState(false);
    
    
    const [updateTerms] = useUpdatePostTermsMutation();
    const [updateTermsOrig] = useUpdateTermsMutation();
    const [updateMedia] = useUpdatePostMediaMutation();
    
    const block_types_query = useGetBlocksTypesQuery();
    const terms_query = useGetTermsQuery({});
    const lang_query = useGetLangsQuery({});
    const media_query = useGetMediaQuery({});


    if(block_types_query.isFetching || terms_query.isFetching || lang_query.isFetching || media_query.isFetching) return <h1>Loading...</h1>
    
    const terms = terms_query.data.data;
    const lang = lang_query.data.data;
    const media = media_query.data.data;
    if(!formContent){
        if(type === 'text') {
            const current_terms = terms.filter(tm => tm.pseudo === pseudo);
            setFormContent(current_terms);
            return;
        }
        if(type === 'image' || type === 'file'){
            const current_media = media.filter(md => md.id === id);
            setFormContent(current_media);
            return;
        }
    }
    
    // ### VIEWS

    const uniqueArray = terms.filter((obj, index, self) => {
        return self.findIndex((otherObj) => obj.group === otherObj.group) === index;
        });
    if(type === 'text'){
        groups_view = uniqueArray.map(el => {
            if(formContent[0].group === el.group) current_group = el.group;
            return(
                <option key={el.id} value={el.group}>{el.group}</option> 
            )
        })
    }
    
    const uniqueArrayMedia = media.filter((obj, index, self) => {
        return self.findIndex((otherObj) => obj.group === otherObj.group) === index;
        });
        groups_view_media = uniqueArrayMedia.map(el => {
            if(formContent[0].group === el.group) current_group_media = el.group;
            return(
                <option key={el.id} value={el.group}>{el.group}</option> 
            )
    });

    // ### FUNCTIONS

    async function sendForm(ev){
        let err_flag = false;
        error_text.current.innerHTML = "";
        ev.preventDefault();
        
        formContent.forEach(el => {
            if(el.value?.length < 2 || el.pseudo?.length < 2 || el.alt?.length < 2) err_flag = "Заповніть усі поля. Мінімум 2 символи.";
        });

        if(err_flag){
            error_text.current.innerHTML = err_flag;
            return;
        }
        
        if(type === 'text'){
            const result = formContent.map(el => {
                return page === 'terms'? updateTermsOrig(el) : updateTerms(el);
            })
    
            Promise.all(result).then(res => {
                if(res[0].error?.data.message === 1000){
                    Swal.fire({
                        title: 'На жаль, у вас немає необхідних прав ',
                        confirmButtonText: 'Закрити',
                        customClass: {
                          actions: 'modal',
                          cancelButton: 'order-1 right-gap',
                          confirmButton: 'order-2',
                          denyButton: 'order-3',
                        }
                    });
                }else{
                    dispatch(termsApi.util.resetApiState());
                    Toast.fire({icon: 'success', title: 'Успіх'})
                }
                close(false);
            });
        }
        
        if(type === 'image' || type === 'file'){
            const form_data = new FormData();
            form_data.append("file", img_input.current.files[0]);
            form_data.append("group", formContent[0].group);
            form_data.append("alt", formContent[0].alt);
            form_data.append("media_id", formContent[0].id);
          
            const result = await updateMedia(form_data);
            console.log(result);
            if(result.data.message === 28){
                dispatch(mediaApi.util.resetApiState());
                Toast.fire({icon: 'success', title: 'Успіх'})
                close(false);
            }
        }
        
    }

    function previewImage(input){
        if(input.name === "image"){
            const reader = new FileReader();
            reader.onload = function(e) {
            img_wrapper.current.style.cssText= `background: url(${e.target.result}) no-repeat center; background-size: contain;`;
            };
            reader.readAsDataURL(img_input.current.files[0]);
        }

        if(input.name === "video"){
            const reader = new FileReader();
            reader.onload = function(e) {
                video.current.src = e.target.result;
                video.current.setAttribute('controls', true);
            };
            reader.readAsDataURL(input.files[0]);
        }
    }

    function changeGroup(ev){
        if(ev.target.value.length){
            group_select.current.disabled = true;
        }else group_select.current.disabled = false;
    }

    function changeContent(el, mode){
        setFormContent(prev => {
            const state = JSON.parse(JSON.stringify(prev));
            let newState = [];
            if(mode === 'group'){
                newState = state.map(tm => {
                    if(el.value.length) tm[`${mode}`] = el.value;
                    else{
                        let old_group;
                        if(type === 'text') terms.forEach(tm => tm.id === id? old_group = tm.group : null);
                        if(type === 'image') media.forEach(md => md.id === id? old_group = md.group : null);
                        tm[`${mode}`] = old_group;
                    }
                    return tm;
                })
            }
            else if(mode === 'alt'){
                state[0].alt = el.value;
                return state;
            }
            else{
                newState = state.map(tm => {
                    if(tm.id == el.id){
                        tm[`${mode}`] = el.value;
                        return tm;
                    } else return tm;
                })
            }
            return newState;
        });
    }


    return(
        <div className="modal_wrapper">
            <div className="modal">
                <h2>{"Змінити Блок"}</h2>
                <form className="modal_form" >
                    <div className="form_row">
                        <label>Тип</label>
                        <select disabled={true} type="select" ref={type_select}>
                            <option value={type}>{type}</option>
                        </select>
                    </div>
                    {
                    formContent.map((el, index) => {
                        if(type === "text"){
                            return(
                                <div key={index} className="form_row">
                                    <label>{`Текст - ${lang.find(ln=>ln.id===el.lang_id).key}`}</label>
                                    <textarea type={el.type} id={el.id} value={el.value} onChange={ev => changeContent(ev.target, 'value')} rows="10"></textarea>
                                </div>
                            )     
                        }
                        else{
                            return(
                                <div key={index} className="form_row">
                                    <label>Вибрати файл</label>
                                    {type === 'image' || type === "file"?
                                        <input type="file" accept="image/*" name='image' ref={img_input} onChange={(ev)=>previewImage(ev.target)}/>
                                        :<input type="file" accept="video/*" name={el.name}/>
                                    }
                                    {type === "image" || type === "file"? (
                                        <div>
                                            <div className='img_wrapper' style={{background: `url(${el.link}) no-repeat center/contain`}} ref={img_wrapper}></div>
                                            <label>Alt</label>
                                            <input type='text' value={el.alt} name='alt' onChange={(ev)=> changeContent(ev.target, 'alt')}/>
                                        </div>
                                    ) : null}
                                    {type === "video"? <video playsInline ref={video} src='' controls={false} style={{width: "100%", height: "100%"}} />: null}
                                </div>
                            )
                        }
                    })
                    }
                   
                        
                    <div className='form_group'>
                        <div className="form_row_group">
                            <div className="left">
                                <label>Вибрати группу</label>
                                <select ref={group_select} value={type === 'text'? current_group:current_group_media} onChange={ev => changeContent(ev.target, 'group')} name="group">
                                    {type === 'image' || type === 'file'? groups_view_media : groups_view}
                                </select>
                            </div>
                            <div className="right">
                                <label>Додати нову</label>
                                <input type="text"  onChange={(ev)=>{changeGroup(ev); changeContent(ev.target, 'group')}}/>
                            </div>
                        </div>
                        {type === "text" || type === "link"? (
                            <div className="form_row">
                            <label>Псевдонім</label>
                            <input value={formContent[0].pseudo} disabled/>
                        </div>
                        ) : null}
                    </div>
                    
                    
                     <span className="error_text" ref={error_text}></span>
                    <input className="button" type="button" value="Відправити" onClick={(ev)=>{sendForm(ev)}} />
                    <input className="button" type="submit" value="Скасувати" onClick={()=> close(false)}/>
                </form>
            </div>
        </div>
    )
}