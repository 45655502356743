import React from 'react';
import s from './instagram.module.scss';

export const Instagram = ()=>{

    const token = false;

    function auth({ login, password }){

    }

    return(
        <div className={s.module}>
            <h1>Instagram</h1>
            <div className={s.content}>
                
                {

                token? 
                null
                :
                <>
                    {/* <h2>Авторизация Facebook</h2>
                   
                        <form>
                            <div className={s.inputs}>
                                <div className={s.input_wrapper}>
                                    <label htmlFor="login">Login</label>
                                    <input type="text" name="login"/>
                                </div>
                                <div className={s.input_wrapper}>
                                    <label htmlFor="password">Password</label>
                                    <input type="text" name='password'/>
                                </div>
                            </div>
                            <div className="button">Отправить</div>
                        </form> */}
                        <div class="fb-login-button" data-width="200" data-size="" data-button-type="" data-layout="" data-auto-logout-link="true" data-use-continue-as="true"></div>
                </>
                }


            </div>
        </div>
    )
}