import React from 'react';
import s from './menuMini.module.scss';

export const MenuMini = ({items, refLink, name})=>{

    const items_view = items.map((el, index) => {
        return(
            <div key={index} className={s.item} onClick={()=>{el.callback(); refLink[name].style.cssText = 'opacity: 0; visibility: hidden'}}>
                <img src={el.icon} alt="icon" />
                <span>{el.text}</span>
            </div>
        )
    })

    return(
        <div className={s.menu_wrapper} ref={(el) => refLink[name] = el}>
            <div className={s.menu}>
                {items_view}
            </div>
            <div className={s.close_wrapper} onClick={()=>refLink[name].style.cssText = 'opacity: 0; visibility: hidden'}></div>
        </div>
    )
}