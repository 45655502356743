import React from 'react';
import s from './button.module.scss';
import {ReactComponent as Loading} from '../../assets/icons/loading.svg'

export const Button = ({callback, text, type, isLoading})=>{
    return(
        <div className={`${s.button} ${type === 'close'? s.close : null}`} onClick={callback}>
            {isLoading? <Loading/> : <span>{text}</span>}
        </div>
    )
}