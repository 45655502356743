import React, { useEffect, useRef, useState } from 'react';
import s from './moduleBlock.module.scss';
import { ModuleItem } from '../moduleItem/moduleItem';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete_icon.svg'
import { ReactComponent as SettingIcon } from '../../../assets/icons/settings_page_icon.svg'
import { useDeletePostMutation } from '../../../redux/apis/modulesApi';
import Swal from 'sweetalert2';
import { Toast } from '../../../utils/hocs/toast';
import { getSequenceTypes } from '../../../utils/getSequenceTypes';
import { useSelector } from 'react-redux';
import { PageSettingsModal } from '../../pageSettingsModal/pageSettingsModal';

export const ModuleBlock = ({ post, types, setModal, inner = false, setNewModuleData, setViewMode, parrent_id, index, langState, setLangState })=>{

    const langs = useSelector(store => store.main.langs)
    const content = useRef()
    const item = useRef()
    const current_type = types.find(type => type.title === post.title)
    
    const [deletePost] = useDeletePostMutation();
    const [pageSettingsModalState, setPageSettingsModalState] = useState(false)

    useEffect(()=>{
        if(inner) item.current.style.cssText= `width: 90%; margin: 0 auto 20px;`
    },[]);
    
    const module_items_view = post.data?.map((el, index) => {
        return <ModuleItem key={index} item={el} setModal={setModal} types={types} setViewMode={setViewMode} setNewModuleData={setNewModuleData} parrent_id={post.id} index={index} langState={langState} setLangState={setLangState}/>
    })
    
    async function deleteBlock(){
        Swal.fire({
            title: 'Видалити блок?',
            text: `Ви впевнені що хочете видалити блок? Разом з ним буде видалено весь його контент!`,
            showCancelButton: true,
            cancelButtonText: 'Скасувати',
            confirmButtonText: 'Видалити',
            icon: 'question',
            customClass: {
              actions: 'modal',
              cancelButton: 'order-1 right-gap',
              confirmButton: 'order-2',
              denyButton: 'order-3',
            }
          }).then(async (result) => {
            if(result.isConfirmed){
                const response = await deletePost(post.id);
                if(response.data){
                    Toast.fire({icon: 'success', title: 'Успіх'});
                }
                else if(response.error?.data?.message === 1000){
                    Swal.fire({
                        title: 'На жаль, у вас немає необхідних прав',
                        confirmButtonText: 'Закрити',
                        customClass: {
                          actions: 'modal',
                          cancelButton: 'order-1 right-gap',
                          confirmButton: 'order-2',
                          denyButton: 'order-3',
                        }
                      })
                      return;
                }
                else Toast.fire({icon: 'error', title: 'Помилка'});
            }
        })
    }

    function addNewBlock(){
        setViewMode(false)
        const res = getSequenceTypes(post.id, types, post.title, langs, parrent_id, index, true)
        setNewModuleData(res)
    }


    return(
        <div className={`${s.item} anchor-${post.id}`} ref={item}>
            <header>
                <span>{`${post.title} (${post.id})`}</span>
                {
                    current_type.type !== 'array' &&
                    <div className={s.tools}>
                        {
                            current_type.type === 'page' &&
                            <span className={s.delete_icon} onClick={()=>setPageSettingsModalState({id: post.id, values: post.params})}><SettingIcon/></span>  
                        }
                        <span className={s.delete_icon} onClick={()=>deleteBlock()}><DeleteIcon/></span>
                    </div>
                }
            </header>
            <div className={s.content} ref={content}>

                {
                post.data?
                <div>
                    {module_items_view}
                    {post.isArray? <div className="add_module_button" onClick={()=>addNewBlock()}>{`Додати ${post.title}`}<span>&#11015;</span></div> : null}
                </div> :
                <ModuleItem key={post.id} item={post} setModal={setModal} types={types} setViewMode={setViewMode} setNewModuleData={setNewModuleData} langState={langState} setLangState={setLangState}/>
                }

            </div>
            {pageSettingsModalState? <PageSettingsModal item={pageSettingsModalState.id} data={pageSettingsModalState.values} close={()=>setPageSettingsModalState(false)} type="module"/> : null}
        </div>
    )
}