import React, { useEffect, useRef, useState } from 'react';
import { useUpdateDefaultImageMutation, useUpdatePostMutation } from '../../../redux/apis/modulesApi';
import { Toast } from '../../../utils/hocs/toast';
import Swal from 'sweetalert2';
import s from './mediaModal.module.scss'
import { useSelector } from 'react-redux';

export const MediaModal = ({close, values, type, post_id})=>{
  
    const [altValue, setAltValue] = useState(values.alt);
    const [newFile, setNewFile] = useState([]);
    const [defaultImage, setDefaultImage] = useState({langkey: values.find(el => el.default_image)?.langkey, id: values.find(el => el.default_image)?.id})
    const [activeImages, setActiveImages] = useState({})

    const ln = useSelector(store => store.main.langs)
    const error_text = useRef();
    const video = useRef()
    const img_wrapper = useRef({})

    const [updatePost] = useUpdatePostMutation();
    const [updateDefaultImage] = useUpdateDefaultImageMutation()

    useEffect(()=>{
        values?.forEach(el => {
            if(el.link)
            setActiveImages(prev => {return{ ...prev, [el.langkey]: true }})
        })
    },[values]);

    async function sendData(){

        if(defaultImage.langkey !== values.find(el => el.default_image)?.langkey && !newFile.length){
            const response = await updateDefaultImage({imageId: defaultImage.id, defaultStatus: true})
            if(response.data?.message === 28){
                Toast.fire({icon: 'success', title: 'Успіх'});
                close(false)
            }
            else Toast.fire({icon: 'error', title: 'Помилка'});
        }

        if(!newFile.length){
            close(false)
            return
        }

        await Promise.all(newFile.map(async file => {
            const formData = new FormData();
            if(!!file.file) formData.append('file', file.file)
            formData.append('alt', 'image')
            formData.append('with_file', 'true')
            formData.append("post_id", post_id)
            formData.append("type", type)
            formData.append("langkey", file.langkey)
            formData.append("default", "false")

            const response = await updatePost(formData);

            if(response.data?.message === 28){
                const defaultResponse = await updateDefaultImage({imageId: defaultImage.id, defaultStatus: true})
                if(defaultResponse.data?.message === 28){
                    Toast.fire({icon: 'success', title: 'Успіх'});
                    close(false)
                }
                else Toast.fire({icon: 'error', title: 'Помилка'});
            }
            else if(response.error?.data?.message === 1000){
                Swal.fire({
                    title: 'На жаль, у вас немає необхідних прав',
                    confirmButtonText: 'Закрити',
                    customClass: {
                      actions: 'modal',
                      cancelButton: 'order-1 right-gap',
                      confirmButton: 'order-2',
                      denyButton: 'order-3',
                    }
                  })
                  return;
            }
            else Toast.fire({icon: 'error', title: 'Помилка'});
        }))
    }

    function previewImage(input, langkey, fileId){
        if(input.name === "image"){
            const reader = new FileReader();
            reader.onload = function(e) {
            img_wrapper.current[langkey].style.cssText= `background: url(${e.target.result}) no-repeat center; background-size: contain;`;
            };
            reader.readAsDataURL(input.files[0]);
            setNewFile(prev => {
                const newPrev = prev.filter(el => el.langkey !== langkey)
                const newImage = {file: input.files[0], langkey, id: fileId}
                return [...newPrev, newImage]
            })
            setActiveImages(prev => {return{ ...prev, [langkey]: true }})
        }

        if(input.name === "video"){
            const reader = new FileReader();
            reader.onload = function(e) {
                video.current.src = e.target.result;
                video.current.setAttribute('controls', true);
            };
            reader.readAsDataURL(input.files[0]);
        }
    }
 
    return(
        <div className={s.modal_wrapper}>
            <div className={s.modal}>
                <h2>Редагувати блок</h2>
                
                <div className={s.content}>
                    <div className={s.image_items_wrapper}>
                        {ln?.map((ln, index) => (
                                <div className={s.image_item_wrapper} key={index}>
                                    <div className={s.header}>
                                        <span className={s.lang}>{ln.key} |</span>
                                        <div className={s.default_wrapper}>
                                        <input type="checkbox" checked={defaultImage.langkey === ln.key} onChange={()=>setDefaultImage({langkey: ln.key, id: values?.find(el=>el.langkey === ln.key)?.id})} disabled={!activeImages[ln.key]}/>
                                            <span>default</span>
                                        </div>
                                    </div>
                                    <div className={s.image_wrapper} ref={(el) => img_wrapper.current[ln.key] = el} style={{backgroundImage: `url(${values?.find(el=>el.langkey === ln.key)?.link})`}}>
                                        <input className={s.change_input} name='image' type="file" accept='image/*' onChange={ev => previewImage(ev.target, ln.key, values?.find(el=>el.langkey === ln.key)?.id)}/>
                                    </div>
                                </div>
                        ))}
                    </div>
                    <div className={s.footer}>
                        <input className="button" type="button" value="Відправити" onClick={(ev)=>sendData()} />
                        <input className="button" type="submit" value="Скасувати" onClick={()=>close(false)}/>
                    </div>
                </div>
            </div>
        </div>
    )
}