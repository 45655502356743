import React from 'react';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import { sectionApi } from '../../../../redux/apis/sectionApi';
import { useDispatch, useSelector } from 'react-redux';
import { pagesApi, useAddPageMutation, useUpdatePageMutation } from '../../../../redux/apis/pagesApi';
import { Toast } from '../../../../utils/hocs/toast';
import Swal from 'sweetalert2';

export const PagesModal = ({close, el, pageState})=>{
    
    const langs = useSelector(store => store.main.langs)
    let modal_errors;
    const dispatch = useDispatch();
    const initialValues = {
        id: el? el.id : '', 
        name: el? el.name : '', 
        content: el? el.content : '',
    };

    langs.forEach(lang => {
        initialValues[`title-${lang.key}`] = el? el.params?.head?.title[lang.key] : ''
        initialValues[`description-${lang.key}`] = el? el.params?.head?.description[lang.key] : ''
        initialValues[`keywords-${lang.key}`] = el? el.params?.head?.keywords[lang.key] : ''
    })

    const validation_data = {
        name: yup.string().required("Обов'язкове поле").min(2, 'Нимимум 2 символа')
    }
    langs.forEach(el => {
        validation_data[`title-${el.key}`] = yup.string().required("Обов'язкове поле").min(2, 'Нимимум 2 символа')
        validation_data[`description-${el.key}`] = yup.string().required("Обов'язкове поле").min(2, 'Нимимум 2 символа')
        validation_data[`keywords-${el.key}`] = yup.string().required("Обов'язкове поле").min(2, 'Нимимум 2 символа')
    })
    
    const validationSchema = yup.object().shape(validation_data);

    const [setPage] = useAddPageMutation();
    const [updatePage] = useUpdatePageMutation();

    return(
        <div className="modal_wrapper">
            <div className="modal">
                <h2>{el? "Редагувати сторінку" : "Додати сторінку"}</h2>
                <Formik
                    validationSchema={validationSchema}
                    initialValues={initialValues}
                    onSubmit={async (values, {setSubmitting, resetForm})=> {
                        setSubmitting(false)
                        const head = {title: {}, description: {}, keywords: {}}
                        langs.forEach(el => {
                            head.title[el.key] = values[`title-${el.key}`]
                            head.description[el.key] = values[`description-${el.key}`]
                            head.keywords[el.key] = values[`keywords-${el.key}`]
                        })
                        if(el){
                            const data = {id: initialValues.id, name: values.name, head: head}
                            const res = await updatePage(data);
                            if(res.error?.data.message === 1000){
                                console.log(res);
                                Swal.fire({
                                    icon: 'error',
                                    title: 'На жаль, у вас немає необхідних прав',
                                    confirmButtonText: 'Закрити',
                                    customClass: {
                                      actions: 'modal',
                                      cancelButton: 'order-1 right-gap',
                                      confirmButton: 'order-2',
                                      denyButton: 'order-3',
                                    }
                                })
                                close(false);
                            }
                            else if(res.error?.data.message === 30){
                                Toast.fire({icon: 'error', title: `Сталася помилка`});
                            }
                            else{
                                console.log(res.data);
                                dispatch(pagesApi.util.resetApiState(['Pages']));
                                dispatch(sectionApi.util.resetApiState(['Section']));
                                Toast.fire({icon: 'success', title: `Успіх`});
                                close(false);
                            }
                        }
                        else {
                            const data = {id: initialValues.id, name: values.name, head: head}
                            const res = await setPage(data);
                            if(res.error?.data.message === 1000){
                                Swal.fire({
                                    icon: 'error',
                                    title: 'На жаль, у вас немає необхідних прав',
                                    confirmButtonText: 'Закрити',
                                    customClass: {
                                      actions: 'modal',
                                      cancelButton: 'order-1 right-gap',
                                      confirmButton: 'order-2',
                                      denyButton: 'order-3',
                                    }
                                })
                                close(false);
                            }
                            else if(res.error?.data.message === 30){
                                Toast.fire({icon: 'error', title: `Имя ${res.error.data.data.name} уже занято`});
                            }
                            else{
                                dispatch(pagesApi.util.resetApiState(['Pages']));
                                dispatch(sectionApi.util.resetApiState(['Section']));
                                Toast.fire({icon: 'success', title: `Успіх`});
                                close(false);
                            }
                        }
                    }}
                    >
                    {({errors, touched}) => (
                        <Form className="modal_form">
                        
                        <div className="form_row">
                            <label>
                                Назва сторінки
                            </label>
                            <Field name="name" type="text"/>
                            <span className="error_text">{touched.name? errors.name : null}</span>
                        </div>
                        {langs.map((el, index) => {
                            return(
                                <div key={index} className="form_row">
                                    <label>Title <span>{el.key}</span></label>
                                    <Field name={`title-${[el.key]}`} type="text"/>
                                    <span className="error_text">{touched[`title-${el.key}`]? errors[[`title-${el.key}`]] : null}</span>
                                </div>
                            )
                        })}
                        {langs.map((el, index) => {
                            return(
                                <div key={index} className="form_row">
                                    <label>Description <span>{el.key}</span></label>
                                    <Field name={`description-${[el.key]}`} type="text"/>
                                    <span className="error_text">{touched[`description-${el.key}`]? errors[[`description-${el.key}`]] : null}</span>
                                </div>
                            )
                        })}
                        {langs.map((el, index) => {
                            return(
                                <div key={index} className="form_row">
                                    <label>Keywords <span>{el.key}</span></label>
                                    <Field name={`keywords-${[el.key]}`} as="textarea" rows="10"/>
                                    <span className="error_text">{touched[`keywords-${el.key}`]? errors[[`keywords-${el.key}`]] : null}</span>
                                </div>
                            )
                        })}

                        <button className='button' type="submit">Відправити</button>
                        <button className='button' type="submit" onClick={()=>close(false)}>Скасувати</button>
                        <span className="error_text">{modal_errors}</span>
                    </Form>
                    )}
                </Formik>
            </div>
        </div>
    )
}