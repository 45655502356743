import { Formik, Form, Field } from 'formik';
import React, { useState } from 'react';
import s from './registration.module.scss';
import * as yup from 'yup';
import { useAddUserMutation, useUpdateUserMutation } from '../../../redux/apis/userApi';
import { useCrypto } from '../../../utils/hocs/crypto';
import { Toast } from '../../../utils/hocs/toast';
import { useEffect } from 'react';

export const Registration = ({close, user})=>{
    
    const Swal = require('sweetalert2');
    let response = false;

    const [permissions, setPermissions] = useState(
        {"terms": {"view": false, "edit": false},
        "pages": {"view": false, "create": false, "edit": false, "delete": false},
        // "posts": {"view": false, "my": false, "all": false},
        "modules": {"view": false, "create": false},
        "langs": {"view": false},
        "site": {"view": false}
        },
    );
    
    useEffect(()=>{
        if(user) setPermissions(user.permissions)
    },[]);

    const [addUser] = useAddUserMutation();
    const [updUser] = useUpdateUserMutation();

    const initialValues = { email: user? user.email:'', name: user? user.info.name:'', password: user? '123':'', confirmPassword: user? '123':''}
    const validationSchema = yup.object().shape({
        email: yup.string().required(`Обов'язкове поле`).email('Не коректний email'),
        password: yup.string().required(`Обов'язкове поле`),
        confirmPassword: yup.string().required(`Обов'язкове поле`).oneOf([yup.ref('password'), null], 'Паролі не співпадають'),
        name: yup.string().required(`Обов'язкове поле`),
    });

    async function Registration(values){
        const email = values.email;
        const password = useCrypto(values.password);
        const name = values.name;
        
        const result = await addUser({email: email, password: password, name: name, permissions: permissions});
        if(result.data){
            if(result.data.message === 1){
                Swal.fire({
                    icon: 'error',
                    text: `Електронна пошта ${result.data.data} вже зареєстрована`,
                    confirmButtonColor: "#EC722E"
                });
            }
            if(result.data.message === 7){
                Toast.fire({
                    icon: 'success',
                    title: 'Успіх'
                });
                close(false);
            }
        }else{
            Swal.fire({
                icon: 'error',
                text: `Помилка =(`,
                confirmButtonColor: "#EC722E"
            });
        }
    }

    async function UpdateUser(values){
        const user_id = user.id;
        const email = values.email;
        const name = values.name;
        const result = await updUser({user_id: user_id, email: email, name: name, permissions: permissions});
        if(result.data){
            if(result.data.message === 7){
                Toast.fire({
                    icon: 'success',
                    title: 'Успіх'
                });
                close(false);
            }
        }else{
            Swal.fire({
                icon: 'error',
                text: `Помилка =(`,
                confirmButtonColor: "#EC722E"
            });
        }
    }

    function selectPermission(el){
        const group = el.parentNode.getAttribute('name');
        const element = el.getAttribute('name');

        if(!el.classList.contains(s.active)){
            el.classList.add(s.active);
            setPermissions(prev => {
                const newState = JSON.parse(JSON.stringify(prev));
                newState[`${group}`][`${element}`] = true;
                return newState;
            })
        }
        else{
            el.classList.remove(s.active);
            setPermissions(prev => {
                const newState = JSON.parse(JSON.stringify(prev));
                newState[`${group}`][`${element}`] = false;
                return newState;
            })
        }
        
        
    }

    return(
        <div className={s.auth_wrapper}>
            <div className={s.auth}>
                <h1>Реєстрація</h1>
                <Formik
                    validationSchema={validationSchema}
                    initialValues={initialValues}
                    onSubmit={(values)=> {
                        if(!user) Registration(values);
                        else UpdateUser(values);
                    }}
                    >
                    {({errors, touched}) => (
                        <Form className={s.auth_form}>
                        <div className={s.form_row}>
                            <label>
                                Email
                            </label>
                            <Field name="email" type="text"/>
                            <span className={s.error_text}>{touched.email? errors.email : null}</span>
                        </div>

                        <div className={s.form_row}>
                            <label>
                                Name
                            </label>
                            <Field name="name" type="text"/>
                            <span className={s.error_text}>{touched.name? errors.name : null}</span>
                        </div>

                        <div className={s.form_row} style={{display: user? 'none':'flex'}}>
                            <label>
                                Password
                            </label>
                            <Field name="password" type="password"/>
                            <span className={s.error_text}>{touched.password? errors.password : null}</span>
                        </div>

                        <div className={s.form_row} style={{display: user? 'none':'flex'}}>
                            <label>
                                Confirm Password
                            </label>
                            <Field name="confirmPassword" type="password"/>
                            <span className={s.error_text}>{touched.confirmPassword? errors.confirmPassword : null}</span>
                        </div>
                        <div className={s.form_row}>
                            <p className={s.per_title}>Права доступу</p>
                            <div className={s.per_row}>
                                <p className={s.title}>Сторінки/Секції/Блоки</p>
                                <div className={s.per_table} name="pages">
                                    <div className={`${s.per_item} ${permissions['pages']['view']? s.active: "noactive"}`} name="view" onClick={(ev)=>selectPermission(ev.target)}>Перегляд</div>
                                    <div className={`${s.per_item} ${permissions['pages']['create']? s.active: "noactive"}`} name="create" onClick={(ev)=>selectPermission(ev.target)}>Cтворення</div>
                                    <div className={`${s.per_item} ${permissions['pages']['edit']? s.active: "noactive"}`} name="edit" onClick={(ev)=>selectPermission(ev.target)}>Редагування</div>
                                    <div className={`${s.per_item} ${permissions['pages']['delete']? s.active: "noactive"}`} name="delete" onClick={(ev)=>selectPermission(ev.target)}>Видалення</div>
                                </div>
                            </div>
                            <div className={s.per_row}>
                                <p className={s.title}>Терміни</p>
                                <div className={s.per_table} name="terms">
                                    <div className={`${s.per_item} ${permissions['terms']['view']? s.active: "noactive"}`} name="view" onClick={(ev)=>selectPermission(ev.target)}>Перегляд</div>
                                    <div className={`${s.per_item} ${permissions['terms']['edit']? s.active: "noactive"}`} name="edit" onClick={(ev)=>selectPermission(ev.target)}>Редагування</div>
                                </div>
                            </div>
                            {/* <div className={s.per_row}>
                                <p className={s.title}>Пости</p>
                                <div className={s.per_table} name="posts">
                                    <div className={`${s.per_item} ${permissions['posts']['view']? s.active: "noactive"}`} name="view" onClick={(ev)=>selectPermission(ev.target)}>Просмотр</div>
                                    <div className={`${s.per_item} ${permissions['posts']['my']? s.active: "noactive"}`} name="my" onClick={(ev)=>selectPermission(ev.target)}>Управління (свої)</div>
                                    <div className={`${s.per_item} ${permissions['posts']['all']? s.active: "noactive"}`} name="all" onClick={(ev)=>selectPermission(ev.target)}>Управління (усі)</div>
                                </div>
                            </div> */}
                            <div className={s.per_row}>
                                <p className={s.title}>Модулi</p>
                                <div className={s.per_table} name="modules">
                                    <div className={`${s.per_item} ${permissions['modules']['view']? s.active: "noactive"}`} name="view" onClick={(ev)=>selectPermission(ev.target)}>Перегляд</div>
                                    <div className={`${s.per_item} ${permissions['modules']['create']? s.active: "noactive"}`} name="create" onClick={(ev)=>selectPermission(ev.target)}>Управлiння</div>
                                    {/* <div className={`${s.per_item} ${permissions['modules']['edit']? s.active: "noactive"}`} name="edit" onClick={(ev)=>selectPermission(ev.target)}>Редагування</div>
                                    <div className={`${s.per_item} ${permissions['modules']['delete']? s.active: "noactive"}`} name="delete" onClick={(ev)=>selectPermission(ev.target)}>Видалення</div> */}
                                </div>
                            </div>
                            <div className={s.per_row}>
                                <p className={s.title}>Мови</p>
                                <div className={s.per_table} name="langs">
                                    <div className={`${s.per_item} ${permissions['langs']['view']? s.active: "noactive"}`} name="view" onClick={(ev)=>selectPermission(ev.target)}>Управління мовами</div>
                                </div>
                            </div>
                            <div className={s.per_row}>
                                <p className={s.title}>Сайт</p>
                                <div className={s.per_table} name="site">
                                    <div className={`${s.per_item} ${permissions['site']['view']? s.active: "noactive"}`} name="view" onClick={(ev)=>selectPermission(ev.target)}>Оновлення сайту</div>
                                </div>
                            </div>
                        </div>

                        <button type="submit">{user? 'Оновити':'Додати'}</button>
                        <button type="submit" onClick={()=>close(false)}>Скасувати</button>
                        <span className={s.error_text}>{response}</span>
                    </Form>
                    )}
                </Formik>
            </div>
        </div>
    )
}