export const findInputValue = (data, targetId, lang_id, new_value) => {
        
        if (data === null || data === undefined) {
          return null;
        }
      
        if (Array.isArray(data)) {
          // Если это массив, перебираем его элементы
          data.forEach(el => {
            findInputValue(el, targetId, lang_id, new_value)
          });
        } else if (typeof data === 'object') {
          // Если это объект, перебираем его свойства
          for(let el in data){
            if (Array.isArray(data[el])) {
                // Если это массив, перебираем его элементы
                data[el].forEach(el => {
                  findInputValue(el, targetId, lang_id, new_value)
                });
            }

            if(data[el] === targetId){
                if(data.type === "text" || data.type === 'link'){
                    data.values = data.values.map(el => {
                        if(el.lang_id === lang_id) return {lang_id: lang_id, value: new_value}
                        else return el
                    })
                }
                if(data.type === "image" || data.type === 'video'){
                    const form_data = new FormData()
                    form_data.append('file', new_value)
                    data.values = form_data
                }
            }
          }
        }
    return data
}