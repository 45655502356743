import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
const api = process.env.REACT_APP_API_ADDRESS;

export const mediaApi = createApi({
    reducerPath: 'mediaApi',
    tagTypes: ['Media'],
    baseQuery: fetchBaseQuery({
      baseUrl: api,
      prepareHeaders: (headers) => {
        const token = localStorage.getItem("token");
        if(token) headers.set("token", token);
        return headers;
      }
    }),
    endpoints: (builder) => ({

      getMedia: builder.query({
        query: () => `/api/media/get`,
        providesTags: (result) =>
        result.data
          ? [
              ...result.data.map(({ id }) => ({ type: 'Media', id })),
              { type: 'Media', id: 'LIST' },
            ]
          : [{ type: 'Media', id: 'LIST' }],
      }),


      addMedia: builder.mutation({
        query: (data) => ({
          url: '/api/media/set',
          method: 'POST',
          body: data,
        }),
        invalidatesTags: [{ type: 'Media', id: 'LIST' }],
      }),

      updateMedia: builder.mutation({
        query: (data) => ({
          url: '/api/media/update',
          method: 'PUT',
          body: data,
        }),
        invalidatesTags: [{ type: 'Media', id: 'LIST' }],
      }),

      deleteMedia: builder.mutation({
        query: (data) => ({
          url: '/api/media/delete',
          method: 'DELETE',
          body: data,
        }),
        invalidatesTags: [{ type: 'Media', id: 'LIST' }],
      })
    
    })  
})

export const {
    useGetMediaQuery,
    useAddMediaMutation,
    useUpdateMediaMutation,
    useDeleteMediaMutation
} = mediaApi;