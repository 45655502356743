export function PreparationModuleData(data, options){
    if (data === null || data === undefined) {
        return null;
    }

    if (Array.isArray(data)) {
        data.forEach(el => {
            PreparationModuleData(el, options)
        })
    }

    else if(typeof data === 'object'){
        for(let el in data){
            if (Array.isArray(data[el])) {
                data[el].forEach(el => {
                    PreparationModuleData(el, options)
                });
            }

            if(options.includes(el)){
                delete data[el]
            }
          }
    }
    return data
}   