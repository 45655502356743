import React from 'react';
import s from './moduleFileItem.module.scss';
import { ReactComponent as SettingsIcon } from '../../../assets/icons/edit_icon.svg'
import { useSelector } from 'react-redux';

export const ModuleFileItem = ({ data, setModal })=>{
    const ln = useSelector(store => store.main.langs)
   
    return(
        <div className={s.wrapper}>
            <header>
            <div className={s.left}>
                    <div className={s.header_item}>
                        <span className={s.title}>type:</span>
                        <span className={s.value}>{data.title}</span>
                        <span className={s.title}>module ID:</span>
                        <span className={s.value}>{data.id}</span>
                        <span className={s.title}>image ID:</span>
                        <span className={s.value}>{data.value[0]?.id}</span>
                    </div>
                </div>
                <div className={s.tools}>
                    <span className={s.edit_icon} onClick={()=>setModal({state: true, values: data.value, type: data.type, post_id: data.id})}>{<SettingsIcon/>}</span>
                </div>
            </header>
            <div className={s.content}>
                {ln?.length && ln?.map((ln, index) => 
                    
                data.value?.find(el => el.langkey === ln.key)?.link &&
                    <div className={s.image_item_wrapper} key={index}>
                        <div className={`${s.header} ${data.value?.find(el => el.langkey === ln.key)?.default_image? s.color : ""}`}>
                            <span className={s.language}>{ln.key}</span>
                            {
                                data.value?.find(el => el.langkey === ln.key)?.default_image &&
                                <span>| Default</span>
                            }
                        </div>
                        <div className={s.image_wrapper} style={{
                            backgroundImage: `url(${data.value?.find(el => el.langkey === ln.key)?.link})`
                        }}>
                            
                        </div>
                    </div>
                    
                )}
            </div>
        </div>
    )
}