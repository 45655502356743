import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
const api = process.env.REACT_APP_API_ADDRESS;

export const langApi = createApi({
    reducerPath: 'langApi',
    tagTypes: ['Lang'],
    baseQuery: fetchBaseQuery({
      baseUrl: api,
      prepareHeaders: (headers) => {
        const token = localStorage.getItem("token");
        if(token) headers.set("token", token);
        return headers;
      }
    }),
    endpoints: (builder) => ({

      getLangs: builder.query({
        query: ({active}) => `/api/lang/get?active=${active? JSON.stringify(active):false}`,
        providesTags: (result) =>
        result?.data
          ? [
              ...result.data.map(({ id }) => ({ type: 'Lang', id })),
              { type: 'Lang', id: 'LIST' },
            ]
          : [{ type: 'Lang', id: 'LIST' }],
      }),


      activateLang: builder.mutation({
        query: (data) => ({
          url: '/api/lang/activate',
          method: 'PUT',
          body: { id: data},
        }),
        // invalidatesTags: [{ type: 'Lang', id: 'LIST' }],
      }),

      setLanguage: builder.mutation({
        query: (data) => ({
          url: '/api/lang/set',
          method: 'POST',
          body: { key: data},
        }),
        invalidatesTags: [{ type: 'Lang', id: 'LIST' }],
      }),

      deleteLanguage: builder.mutation({
        query: (data) => ({
          url: '/api/lang/delete',
          method: 'DELETE',
          body: { id: data},
        }),
        invalidatesTags: [{ type: 'Lang', id: 'LIST' }],
      }),


    }),
})

export const {
 useGetLangsQuery,
 useActivateLangMutation,
 useSetLanguageMutation,
 useDeleteLanguageMutation
} = langApi;