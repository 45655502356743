import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
const api = process.env.REACT_APP_API_ADDRESS;

export const sectionApi = createApi({
    reducerPath: 'sectionApi',
    tagTypes: ['Section'],
    baseQuery: fetchBaseQuery({
      baseUrl: api,
      prepareHeaders: (headers) => {
        const token = localStorage.getItem("token");
        if(token) headers.set("token", token);
        return headers;
      }
    }),
    endpoints: (builder) => ({

      getSections: builder.query({
        query: ({id, name}) => 
`/api/sections/get?id=${id? JSON.stringify(id):false}&name=${name? JSON.stringify(name):''}`,
        providesTags: (result) =>
        result?.data
          ? [
              ...result.data.map(({ id }) => ({ type: 'Section', id })),
              { type: 'Section', id: 'LIST' },
            ]
          : [{ type: 'Section', id: 'LIST' }],
      }),

      addSection: builder.mutation({
        query: ({values, pageState}) => ({
          url: '/api/sections/set',
          method: 'POST',
          body: { name: values.name, content: values.content, page_id: pageState.id},
        }),
        // invalidatesTags: [{ type: 'Section', id: 'LIST' }],
      }),

      updateSection: builder.mutation({
        query: (data) => ({
          url: '/api/sections/update',
          method: 'PUT',
          body: {id: data.id, name: data.name, content: data.content},
        }),
        invalidatesTags: [{ type: 'Section', id: 'LIST' }],
      }),

      addBlockToSection: builder.mutation({
        query: (data) => ({
          url: '/api/sections/addblock',
          method: 'PUT',
          body: data,
        }),
        invalidatesTags: [{ type: 'Section', id: 'LIST' }],
      }),

      deleteSection: builder.mutation({
        query: (data) => ({
          url: '/api/sections/delete',
          method: 'DELETE',
          body: data,
        }),
        invalidatesTags: [{ type: 'Section', id: 'LIST' }],
      }),
      

      }),
})

export const {
  useGetSectionsQuery,
  useUpdateSectionMutation,
  useAddSectionMutation,
  useDeleteSectionMutation,
  useAddBlockToSectionMutation
} = sectionApi;