import React from 'react';
import s from './addPost.module.scss'

export const AddPost = ({ id, title, data })=>{

    const modules_view = data.value.map(el => {
        
    })
   
    return(
        <div className={s.add_post}>
            <h1>{`Додати пост у ${title}`}</h1>
            <div className={s.content}>
                
            </div>
            {/* {textModal.state? <TextModal close={setTextModal} values={textModal.values} langs={textModal.langs} type={textModal.type} post_id={textModal.post_id}/> : null}
            {mediaModal.state? <MediaModal close={setMediaModal} values={mediaModal.values} type={mediaModal.type} post_id={mediaModal.post_id}/> : null} */}
        </div>
    )
}