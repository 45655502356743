import React, { useEffect, useRef, useState } from 'react';
import s from './pageSettingsModal.module.scss';
import { Button } from '../button/button';
import { useUpdatePageSettingsMutation } from '../../redux/apis/modulesApi';
import { Toast } from '../../utils/hocs/toast';
import { ReactComponent as Tick } from '../../assets/icons/tick.svg'
import { useSelector } from 'react-redux';

export const PageSettingsModal = ({close, data, item, type})=>{
    const langs = useSelector(store => store.main.langs)
    const initialState = {
        title: {},
        description: {},
        keywords: {}
    }

    langs.forEach(el => {
        initialState.title[el.key] = data?.head?.title[el.key]? data.head.title[el.key] : ""
        initialState.description[el.key] = data?.head?.description[el.key]? data.head.description[el.key] : ""
        initialState.keywords[el.key] = data?.head?.keywords[el.key]? data.head.keywords[el.key] : ""
    })

    const [values, setValues] = useState(initialState)
    const [lang, setLang]= useState(langs[0].key)

    const langs_ref = useRef([]);
    
    const [updateSettings, {isLoading}] = useUpdatePageSettingsMutation()

    useEffect(()=>{
        langs_ref.current.forEach(el => {
            if(el.id === lang) el.classList.add(s.active)
            else el.classList.remove(s.active)
        })
    },[lang]);

    useEffect(()=>{
        checkComplete()
    },[values]);

    function checkComplete(){
        let result = true;
        const data = []
        langs.forEach(current_lang => {
            const current_data = []
            for(let el in values){
                current_data.push(values[el][current_lang.key])
            }
            data[current_lang.key] = current_data
        })
        for(let key in data){
            const check = data[key].filter(el => el.length < 2)
            const current_lang_bar = langs_ref.current.find(el => el?.id === key)
            if(!check.length){
                current_lang_bar.children[1].style.cssText = "stroke: #5BFF40"
            }
            else{
                current_lang_bar.children[1].style.cssText = "stroke: #35363B"
                result = false
            }
        }
        return result
    }
    
    function changeValue(el, value){
        setValues(prev => {
            const newState = {...prev}
            newState[el][lang] = value
            return newState
        })
    }

    async function sendSettings(){
        const check = checkComplete()
        if(!check) return
        if(isLoading) return
        const data = {
            head: values,
            type: type,
            post_id: item
        }
        const response = await updateSettings({...data})
        if(response.data?.message === 28){
            close()
            Toast.fire({icon: 'success', title: 'Успіх'});
        }
    }

    const lang_bar_view = langs.map((el, index) => {
        if(!el.active) return
        return <div key={index} id={el.key} ref={element => langs_ref.current[el.id] = element} className={s.lang} onClick={()=>setLang(el.key)}>
                    <span>{el.key}</span><Tick/>
                </div>
    })

    return(
        <div className={s.modal_wrapper}>
            <div className={s.modal}>
                <header className={s.header}>
                    <h1>Налаштування cторiнки</h1>
                </header>
                <div className={s.content}>
                    <div className={s.lang_bar}>
                    {lang_bar_view}
                    </div>
                    <div className={s.row}>
                        <div className="title">Title</div>
                        <input className={s.input} type="text" value={values.title[lang]} onChange={(el)=>changeValue("title", el.target.value)}/>
                    </div>
                    <div className={s.row}>
                        <div className="title">Description</div>
                        <input className={s.input} type="text" value={values.description[lang]} onChange={(el)=>changeValue("description", el.target.value)}/>
                    </div>
                    <div className={s.row}>
                        <div className="title">Keywords</div>
                        <textarea type="text" rows={10} value={values.keywords[lang]} onChange={(el)=>changeValue("keywords", el.target.value)}/>
                    </div>
                    <div className={s.buttons}>
                        <Button text="Вiдправити" type="success" callback={sendSettings} isLoading={isLoading}/>
                        <Button text="Скасувати" type="close" callback={close}/>
                    </div>
                </div>
            </div>
        </div>
    )
}