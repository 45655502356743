import React from 'react';
import s from './arrows.module.scss';

export const Arrows = ()=>{

    function scrollToTop(){
        window.scrollTo({left: 0, top: 0, behavior: "smooth"});
    }
    function scrollToBottom(){
        window.scrollTo({left: 0, top: document.body.scrollHeight, behavior: "smooth"});
    }

    return(
        <div className={s.arrows_block}>
            <div className={s.arrow_top} onClick={scrollToTop}>&#11014;</div>
            <div className={s.arrow_bottom} onClick={scrollToBottom}>&#11015;</div>
        </div>
    )
}